import moment from "moment";


/**
 * 
 * @param {number} week
 * @param {number} year 
 */
function to_time(week,year){
    return moment().year(year).week(week).valueOf()
}
export {to_time}