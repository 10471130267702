import moment from "moment";

const chartOptions = (empty_text,fmt_label,title,min=undefined,max=undefined) => ({
    chart: {
        type: "line",
        stacked: false,
        height: 350,
        zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
        },
        toolbar: {
            autoSelected: "zoom",
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth"
    },
    markers: {
        size: 5,
    },
    fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
        }
    },
    yaxis: {
        labels: {
            formatter: fmt_label,
        },
        title: {
            text: title,
        },
    },
    xaxis: {
        type: "datetime",
        min,
        max,
    },
    tooltip: {
        shared: false,
        y: {
            formatter: fmt_label,
        },
    },
    noData: {
        text: empty_text
    }
});
/**
 * 
 * @param {Array<string>} daterange
 * 
 */
function season(daterange){
    this.daterange = daterange;
    /**
     * 
     * @param {number} yr 
     * @returns {Array<string>}
     */
    this.add_yr = (yr)=>{
        return this.daterange.map(el=>{
            return yr+"-"+el;
        });
    }
    this.without_yr=()=>{
        return this.daterange;
    }
}
const timeUtils={
    /**
     * 
     * @param {string} date 
     */
    getMills(date){
        const tmp = new Date(date);
        return tmp.getTime();
    }
}
const seasonA = new season(["01-01","06-30"]);
const seasonB = new season(["07-01","12-31"]);
const dateUtils={
    time:timeUtils,
    this_season:()=>{
        //determine season.
        const mth = moment().month();
        const yr = moment().year();
        return mth<7?seasonA.add_yr(yr):seasonB.add_yr(yr)
    },
    last_season:()=>{
        const mth = moment().month();
        const yr = moment().year();
        return mth<7?seasonB.add_yr(yr-1):seasonA.add_yr(yr);
    },
}


export {chartOptions,dateUtils}