import {axiosApiInstance} from "@/plugins/axios"
/**
 * @param {{project_id:string,supplier:string}} query
 * @returns {Promise<Array<{week:number,year:number,}>}
 */
async function aggregate(query){
    const res = await axiosApiInstance({
        url:process.env.VUE_APP_BACKEND+"/advances/:aggregate_weekly",
        params:query
    });
    return res.data
}
/**
 * 
 * @param {Array<Array<string>>} query
 * @returns {Promise<Array<{result:number}>>} 
 */
async function aggregate_generic(query){
    const params = new URLSearchParams(query);
    const res = await axiosApiInstance({
        url:process.env.VUE_APP_BACKEND+"/advances/:aggregate_generic",
        params
    });
    return res.data
}
export {aggregate,aggregate_generic}