<template>
  <apexchart type="bar" height="280" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts },
  props:["categories","series","fmt_label"],
  data() {
    const fmt_label = this.fmt_label;
    let chartOptions = {
      chart: {
        type: "bar",
        height: 350,
        stacked:true
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories:this.categories,
        labels:{formatter:fmt_label}
      },
      tooltip:{
        y:{
          formatter:fmt_label
        }
      }
    };
    return {chartOptions };
  },
};
</script>

<style>
</style>