/**
 * @param {Array<string>} features
 * @param {Array<object>} data
 */
function get_data(features,data){
    return features.map(el=>{
        const tmp = data.find(entry=>entry["advance_type"]==el) || {result:0}
        return tmp["result"]; 
    });
}
const bar = {get_data}
export {bar}