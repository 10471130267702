<template>
  <b-form @submit.prevent="filterDate">
    <div class="row align-items-center">
      <b-form-group class="col-5" label="Start-date">
        <b-input-group>
          <b-form-input
            :value="sdate"
            class="w-50 mr-1"
            disabled
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="sdate"
              button-only
              size="sm"
              button-variant="primary"
              right
              locale="en-US"
              aria-controls="example-input"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group class="col-5" label="End-date">
        <b-input-group>
          <b-form-input :value="edate" disabled></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="edate"
              button-only
              size="sm"
              button-variant="primary"
              right
              locale="en-US"
              aria-controls="example-input"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div class="col-2 pt-3">
        <b-button variant="primary" type="submit">
          <b-icon-filter></b-icon-filter>
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
export default {
    data(){
        return {
            edate:'',
            sdate:''
        }
    },
    methods:{
      filterDate(){
        this.$emit("filter-date",[this.sdate,this.edate]);
      }
    }
};
</script>