<template>
  <div>
      <apexchart type="line" height="350" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props:["series","options"]
};
</script>