import {axiosApiInstance} from "@/plugins/axios";
/**
 * 
 * @returns {Promise<Array<{crop:string}>>}
 */
async function get_crops(){
    const res = await axiosApiInstance({
        url:process.env.VUE_APP_BACKEND+"/api/v3/produce/bulked_crops",
    });
    return res.data;
}
/**
 * 
 * @param {project_id:number,crop:string} query 
 * @returns {Promise<Array{week:number,year:number;result:number}>}
 */
async function aggregate_weekly(query){
    const res = await axiosApiInstance({
        url:process.env.VUE_APP_BACKEND+"/api/v3/produce/:aggregate_weekly",
        params:query
    })
    return res.data;
}
/**
 * 
 * @param {project_id:number} query 
 * @returns {Promise<number>}
 */
async function aggregate_numeral(query){
    const res = await axiosApiInstance({
        url:process.env.VUE_APP_BACKEND+"/api/v3/produce/:aggregate_numeral",
        params:query
    });
    return res.data["numeral"];
}
export {get_crops,aggregate_weekly,aggregate_numeral}