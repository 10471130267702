<template>
  <div class="pl-2">
    <div class="w-75">
      <div class="card-bdr card">
        <div class="d-flex">
          <b-nav vertical class="w-25 justify-content-center" justified>
            <b-nav-item
              v-for="category in icategories"
              :key="category"
              @click="
                () => {
                  icategory = category;
                  getSeries();
                }
              "
            >
              <span
                :class="{ 'text-dark': category == icategory }"
                class="text-capitalize"
                >{{ category }}</span
              >
              <b-icon-caret-right
                class="position-relative float-right"
                :class="{ 'text-dark': category == icategory }"
              ></b-icon-caret-right>
            </b-nav-item>
          </b-nav>
          <div class="w-75 pl-2">
            <div class="row align-items-center pt-2">
              <b-col cols="3">
                <b-form-select v-model="supplier_category" @change="getSeries">
                  <b-form-select-option :value="null"
                    >All Suppliers</b-form-select-option
                  >
                  <b-form-select-option value="farmer"
                    >Farmer</b-form-select-option
                  >
                  <b-form-select-option value="va">Agent</b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="3">
                <b-form-select
                  v-model="timeline"
                  :options="timelines"
                  @change="selectTimeline"
                  name="radio-btn-outline"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >All Time</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-col>
              <br />
              <b-col cols="8">
                <date-filter
                  v-show="timeline == 'cust'"
                  @filter-date="filterdate"
                ></date-filter>
              </b-col>
            </div>
            <line-chart :series="series" :options="lnoptions"></line-chart>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <h6 class="text-muted">INPUTS TAKEN BY CATEGORY</h6>
        <bar-graph
          :categories="bar_categories"
          :series="bar_series"
          :fmt_label="format_label"
        ></bar-graph>
      </div>
    </div>
  </div>
</template>

<script>
import BarGraph from "./components/BarGraph.vue";
import LineChart from "../../components/LineChart.vue";
import { Inputs, Produce } from "../../modules";
import millify from "millify";
import DateFilter from "../produce/components/DateFilter.vue";
import { chartOptions, dateUtils } from "../../modules/produce/ui.utils";
var _ = require("lodash");
export default {
  components: { BarGraph, LineChart, DateFilter },
  data() {
    const format_label = (val) => millify(val);

    return {
      lnoptions: chartOptions("No Farm Inputs yet", format_label, ""),
      icategories: [
        "All Categories",
        "Fertilizer",
        "Farm tools",
        "Pesticides",
        "Seeds",
        "Herbicides",
        "Cash",
      ],
      icategory: "All Categories",
      supplier_category: null,
      series: [{ name: "UGX", data: [] }],
      format_label,
      bar_categories: [
        "Cash",
        "Seeds",
        "Pesticides",
        "Herbicides",
        "Farm tools",
        "Fertilizer",
      ],
      timelines: [
        { text: "This Season", value: "this_season" },
        { text: "Last Season", value: "last_season" },
        { text: "Date Range", value: "cust" },
      ],
      timeline: null,
      bar_series: [
        {
          name: "PAID (UGX)",
          data: [230, 500, 800, 700, 600, 780],
        },
        {
          name: "NOT PAID (UGX)",
          data: [100, 200, 300, 400, 500, 380],
        },
      ],
    };
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  mounted() {
    this.getSeries();
    this.getPaymentData();
  },
  methods: {
    filterdate(daterange) {
      let [min, max] = daterange;
      this.lnoptions = chartOptions(
        "No Farm Inputs yet",
        this.format_label,
        "",
        dateUtils.time.getMills(min),
        dateUtils.time.getMills(max)
      );
    },
    selectTimeline() {
      if (this.timeline != "cust") {
        const cb = dateUtils[this.timeline];
        let [min, max] = cb();
        this.lnoptions = chartOptions(
          "No Farm Inputs yet",
          this.format_label,
          "",
          dateUtils.time.getMills(min),
          dateUtils.time.getMills(max)
        );
      }
    },
    getSeries() {
      const query = {
        project_id: this.projectId,
        supplier: this.supplier_category,
        "aggregate[sum]": "amount",
      };
      if (this.icategory != "All Categories")
        query["advance_type"] = this.icategory;
      Inputs.api.aggregate(query).then((data) => {
        const results = data.map((el) => {
          return [Produce.utils.to_time(el["week"], el["year"]), el["result"]];
        });
        this.series = [
          { name: "UGX", data: _.orderBy(results, (el) => el[0]) },
        ];
      });
    },
    getPaymentData() {
      const query = [
        ["project_id", this.projectId],
        ["fields", "advance_type"],
        ["fields", "payment_status"],
        ["aggregate[sum]", "amount"],
      ];
      Inputs.api.aggregate_generic(query).then((data) => {
        const paid = data.filter((el) => el["payment_status"] == "paid");
        const not_paid = data.filter(
          (el) => el["payment_status"] == "not_paid"
        );
        const paid_series = {
          name: "PAID (UGX)",
          data: Inputs.utils.bar.get_data(this.bar_categories, paid),
        };
        const not_paid_series = {
          name: "NOT PAID (UGX)",
          data: Inputs.utils.bar.get_data(this.bar_categories, not_paid),
        };
        this.bar_series = [paid_series, not_paid_series];
      });
    },
  },
};
</script>

<style>
</style>